<template>
  <div>
    <div class="resultsHeading" :class="{'adtBK' : develop == 'adt'}">
      <h2 class="resultsText">Order of Merit</h2>
    </div>
    <b-table v-if="Array.isArray(stats)" :fields="fields" :items="stats.slice()" striped :class="{'adtBK' : develop == 'adt'}">
      <template #cell(VALUE)="row">
        <template v-if="row.item.VALUE_TYPE == 'Points' && row.item.CURRENCY">
          <span>{{row.item.VALUE}} {{row.item.CURRENCY}}{{row.item.CURRENCY_SIGN}}</span>
        </template>
        <template v-else>
          <span v-if="row.item.VALUE_TYPE == 'Points'">{{row.item.VALUE}} (pts)</span>
        </template>
      </template>
      <template #cell(POSITION)="row">
        <span class="posPositionFirst">{{ row.item.POSITION }}</span>
      </template>
      <template #cell(TOURNS)="row">
        <span class="posPosition">{{ row.item.TOURNS }}</span>
      </template>
    </b-table>
    <table v-else :class="{'adtBK' : develop == 'adt'}">
      <thead>
        <tr>
          <th class="title">
            Season
          </th>
          <th class="title">
            Pos
          </th>
          <th class="title">
            Winnings
          </th>
          <th class="title">
            Played
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="cell">
            {{ stats.SEAS_NAME }}
          </td>
          <td class="cell middleAlign">
            {{ stats.POSITION }}
          </td>
          <td class="cell">
            <span v-if="stats.CURRENCY_SIGN.length">
              {{ stats.CURRENCY_SIGN }} 
            </span>
            {{ stats.VALUE }} 
            <template v-if="stats.VALUE_TYPE == 'Points' && stats.CURRENCY.length">
              <span v-if="stats.VALUE_TYPE == 'Winnings'">{{stats.CURRENCY}}</span>
            </template>
            <template v-else>
              <span v-if="stats.VALUE_TYPE == 'Points'">(pts)</span>
            </template>
          </td>
          <td class="cell middleAlignPlayed">
            {{ stats.TOURNS }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "playerOom",
  props: ["stats" , "develop"],
  data() {
    return {
      fields: [
        { key: "SEAS_NAME", label: "Season" },
        { key: "POSITION", label: "Pos", class: "middleAlign" },
        { key: "VALUE", label: "Winnings" },
        { key: "TOURNS", label: "Played", class: "middleAlignPlayed" }
      ]
    };
  }
};
</script>

<style scoped>
.resultsHeading.adtBK {
  background-color: #1c857c;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > thead {
  background-color: #1c857c!important;
}
::v-deep .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff!important;
}
table.b-table.TrRow > tbody > tr:nth-child(odd) {
  background-color: #fff;
}
/* td.tournColour {
  color: #042F62;
  font-weight: 500;
} */
tr:nth-child(odd) {background-color: #fff;}
tr:nth-child(even) {background-color: #7E7E7E;}
.resultsHeading {
  background-color: #042F62;
  height: 47px;
}
.resultsText {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 4;
  padding-left: 10px;
}
::v-deep span.posPosition {
  position: relative;
  left: 20px;
}
::v-deep span.posPositionFirst {
  position: relative;
  left: 10px;
}
::v-deep .middleAlign {
  padding-left: 20px!important;
}
::v-deep .middleAlignPlayed {
  padding-left: 30px!important;
}
::v-deep thead {
  color: white;
  background-color: #042F62!important;
}
.cell {
  text-align: left;
  padding-left: 10px;
  padding: 0.75rem;
}
.title {
  text-align: center;
  background-color: #133f7b;
  color: white;
  width: 5%;
  text-align: left;
  font-size: 17px;
  padding-left: 10px;
}
</style>
