<template>
  <div>
    <div class="resultsHeading" :class="{'adtBK' : develop == 'adt'}">
      <h2 class="resultsText">Career Highlights</h2>
    </div>
    <b-table v-if="Array.isArray(stats)" :fields="fields" :items="stats.slice().reverse()" striped :class="{'adtBK' : develop == 'adt'}">
      <template #cell(WINNINGS)="row">
        {{ row.item.WINNINGS }}
      </template>
      <template #cell(NAME)="row">
        <b-link :to="'/schedule/' + row.item.NAME + '/' + year" class="linkName">
          {{ row.item.NAME }}
        </b-link>
      </template>
      <template #cell(VSPAR)="row">
        <span v-if="row.item.VSPAR.length > 0">
          {{ row.item.VSPAR }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </b-table>
    <table v-else :class="{'adtBK' : develop == 'adt'}"> 
      <thead>
        <tr>
          <th class="title">
            Date
          </th>
          <th class="title">
            Name
          </th>
          <th class="title">
            Position
          </th>
          <th class="title">
            Score
          </th>
          <th class="title hide">
            VSPAR
          </th>
          <th class="title hide">
            Round
          </th>
          <th class="title hide">
            Winnings (USD)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="cell">
            {{ stats.DATE }}
          </td>
          <td class="cell">
            <b-link :to="'/schedule/' + stats.CODE + '/' + year" class="linkName">
              {{ stats.NAME }}
            </b-link>
          </td>
          <td class="cell">
            {{ stats.POSITION }}
          </td>
          <td class="cell">
            {{ stats.SCORE }}
          </td>
          <td class="cell hide">
            {{ stats.VSPAR }}
          </td>
          <td class="cell hide">
            {{ stats.ROUNDS }}
          </td>
          <td class="cell hide">{{ stats.CURRENCY }} ${{ stats.WINNINGS }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "playerCareerHigh",
  props: ["stats", "year", "develop"],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "SCORE", label: "Score" },
        { key: "VSPAR", label: "VSPAR", class: "hide"  },
        { key: "ROUNDS", label: "Round", class: "hide"  },
        { key: "WINNINGS", label: "Winnings (USD)", class: "hide" }
      ]
    };
  }
};
</script>

<style scoped>
.resultsHeading.adtBK {
  background-color: #1c857c;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > thead {
  background-color: #1c857c!important;
}
a.linkName {
  color: #042F62!important;
  font-weight: 500;
}
a.linkName:hover {
  color: #5897E6!important;
  text-decoration: none;
}
::v-deep .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff!important;
}
table.b-table.TrRow > tbody > tr:nth-child(odd) {
  background-color: #fff;
}
/* td.tournColour {
  color: #042F62;
  font-weight: 500;
} */
tr:nth-child(odd) {background-color: #fff;}
tr:nth-child(even) {background-color: #7E7E7E;}
.resultsHeading {
  background-color: #042F62;
  height: 47px;
}
.resultsText {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 4;
  padding-left: 10px;
}
::v-deep thead {
  color: white;
  background-color: #042F62!important;
}
.cell {
  text-align: center;
}
.title {
  text-align: center;
  background-color: #263056;
  color: white;
  width: 5%;
}
@media only screen and (max-width: 500px) {
  ::v-deep th.hide {
    display: none;
  }
  ::v-deep td.hide {
    display: none;
  }
  .hide {
    display: none;
  }
}
</style>
